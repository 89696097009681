<template>
  <div>
    <a-card style="box-sizing:border-box;padding:30px">

      <!-- 选择下单的应用列表 -->
      <a-form>
        <!-- <div style="display:flex;flex-direction:row"> -->
        <!-- <a-form-item label="应用APPID">
          <a-select v-model="appId" @change="changeAppId" style="width:500px" placeholder="请选择应用">
            <a-select-option key="" >应用APPID</a-select-option>
            <a-select-option v-for="(item) in mchAppList" :key="item.appId" >{{ item.appName }} [{{ item.appId }}]</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="支付金额">
          <a-input-number
            placeholder="请输入支付金额"
            ref="amountInputFocus"
            :max="500000"
            :min="0.01"
            v-model="paytestAmount"
            :precision="2"
            style="width:500px">
          </a-input-number>
        </a-form-item>
        <!-- </div> -->
      </a-form>
      <a-divider orientation="left">请选择网银</a-divider>
      <div class="gutter-example">
        <a-row :gutter="16">
          <a-col
            class="gutter-row"
            :span="4"
            style="min-width:100px"
            v-for="item in bankList"
            :key="item.id"
          >
            <div class="gutter-box" :class="{active : item.code == bankCode}" @click="handleBankCode(item)">
              <img :src="require('@/assets/bank/'+item.name+'.png')" style="width:100%"/>
            </div>
          </a-col>
        </a-row>
      </div>

      <div style="margin-top:20px;text-align: left">
        <a-button @click="immediatelyPay" style="padding:5px 20px;background-color: #1953ff;border-radius: 5px;color:#fff">立即支付</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { API_URL_MCH_APP, req, payTest, payTestOrder } from '@/api/manage' // 接口

export default {
  props: {},
  data () {
    return {
      mchAppList: [], // app列表
      appId: '', // 已选择的appId
      appPaywayList: [], // 商户app支持的支付方式
      currentWayCode: 'ADAPAY_B2B', // 以何种方式进行支付，默认是微信二维码
      currentPayDataType: '', // 支付参数
      mchOrderNo: '', // 模拟商户订单号
      authCode: '', // 条码的值
      paytestAmount: '', // 支付金额，默认为0.01
      amountInput: false, // 自定金额输入框是否展示
      noConfigText: false, // 尚无任何配置分割线提示文字
      divisionMode: 0, // 订单分账模式
      orderTitle: '接口调试', // 订单标题
      bankList: [
        // { id: '1', name: '北京农商银行' },
        { id: '2', name: '北京银行', code: '03130011' },
        // { id: '3', name: '渤海银行' },
        // { id: '4', name: '甘肃银行' },
        { id: '5', name: '光大银行', code: '03030000' },
        { id: '6', name: '广发银行', code: '03060000' },
        // { id: '7', name: '贵州银行' },
        // { id: '8', name: '杭州银行' },
        { id: '9', name: '华夏银行', code: '03040000' },
        { id: '10', name: '建设银行', code: '01050000' },
        { id: '11', name: '交通银行', code: '03010000' },
        // { id: '12', name: '兰州银行' },
        { id: '13', name: '民生银行', code: '03050000' },
        // { id: '14', name: '南京银行' },
        { id: '15', name: '平安银行', code: '03134402' },
        { id: '16', name: '浦发银行', code: '03100000' },
        // { id: '17', name: '上海农商银行' },
        { id: '18', name: '上海银行', code: '03110031' },
        { id: '19', name: '兴业银行', code: '03090000' },
        { id: '20', name: '招商银行', code: '03080000' },
        // { id: '21', name: '浙商银行' },
        { id: '22', name: '中国工商银行', code: '01020000' },
        { id: '23', name: '中国农业银行', code: '01030000' },
        { id: '24', name: '中国银行', code: '01040000' },
        { id: '25', name: '中国邮储银行', code: '04030000' },
        { id: '26', name: '中信银行', code: '03020000' }
      ],
      bankCode: ''
    }
  },

  mounted () {
    // 获取传入的参数，如果参数存在，则为appId 重新赋值
    const appId = this.$route.params.appId
    if (appId) {
      this.appId = appId // appId赋值
      this.appPaywayListHandle(appId) // 调用appPaywayListHandle，展示支付方式
    }

    const that = this // 提前保留this
    // 请求接口，获取所有的appid，只有此处进行pageSize=-1传参
    req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
      that.mchAppList = res.records
    })

    // 在进入页面时刷新订单号
    this.randomOrderNo()
  },
  methods: {
    handleBankCode (item) {
      this.bankCode = item.code
    },
    // 支付板块是否展示
    payTestShow () {
      // 如果未选择appid，或者支付方式列表为0，则不显示支付体验板块
      if (this.appId === '' || this.appPaywayList.length === 0) {
        return false
      } else {
        return true
      }
    },

    changeCurrentWayCode (wayCode, currentPayDataType) {
      // 切换支付方式
      this.currentWayCode = wayCode
      this.currentPayDataType = currentPayDataType
    },

    // 变更 appId的事件
    changeAppId (value) {
      this.appPaywayListHandle(value) // 根据appId的值，动态显示支付方式
    },

    // 刷新订单号
    randomOrderNo () {
      this.mchOrderNo =
        'M' +
        new Date().getTime() +
        Math.floor(Math.random() * (9999 - 1000) + 1000)
    },

    // 获取条码的值
    barCodeChange (value) {
      this.authCode = value
      this.immediatelyPay()
    },

    // 根据不同的appId展示不同的支付方式(在下拉框切换时和在携带参数进入页面时调用)
    appPaywayListHandle (value) {
      if (!value) {
        this.appPaywayList = []
        return false
      }
      const that = this
      payTest(value).then(res => {
        that.appPaywayList = res
        if (res.length === 0) {
          that.noConfigText = true
        } else {
          that.noConfigText = false
        }
      })
    },
    // 立即支付按钮
    immediatelyPay () {
      if (this.appid === '') {
        return this.$message.error('请选择app应用')
      }
      // 判断支付金额是否为0
      if (!this.paytestAmount || this.paytestAmount === 0.0) {
        return this.$message.error('请输入支付金额')
      }

      // 判断是否选择支付方式
      if (this.bankCode === '') {
        return this.$message.error('请选择网银')
      }

      // 请输入订单标题
      if (!this.orderTitle || this.orderTitle.length > 20) {
        return this.$message.error('请输入正确的订单标题[20字以内]')
      }

      // 判断是否为条码支付
      // if (
      //   !this.$refs.payTestBarCode.getVisible() &&
      //   (this.currentWayCode === 'WX_BAR' ||
      //     this.currentWayCode === 'ALI_BAR' ||
      //     this.currentWayCode === 'AUTO_BAR')
      // ) {
      //   this.$refs.payTestBarCode.showModal()
      //   return
      // }

      const that = this
      payTestOrder({
        // jsapi 默认使用聚合二维码支付
        wayCode:
          this.currentWayCode === 'WX_JSAPI' ||
          this.currentWayCode === 'ALI_JSAPI'
            ? 'QR_CASHIER'
            : this.currentWayCode, // 支付方式
        amount: this.paytestAmount, // 支付金额
        appId: this.appId, // appId
        mchOrderNo: this.mchOrderNo, // 订单编号
        payDataType: this.currentPayDataType, // 支付参数（二维码，条码）
        authCode: this.authCode,
        divisionMode: this.divisionMode,
        orderTitle: this.orderTitle,
        acctIssrId: this.bankCode
      })
        .then(res => {
          console.log(res)
          if (res.payData) {
            const div = document.createElement('divform')
            div.innerHTML = res.payData
            document.body.appendChild(div)
            const len = document.forms.length - 1
            // document.forms[len].setAttribute('target', '_blank') // 新开窗口跳转
            document.forms[len].submit()
          }

          // const div = document.createElement('div')
          // div.innerHTML = res.payData // html code
          // newTab.document.body.appendChild(div)

          // newTab.document.forms.alipaysubmit.submit()

          // that.$refs.payTestModal.showModal(this.currentWayCode, res) // 打开弹窗
          // that.randomOrderNo() // 刷新订单号
        })
        .catch(() => {
          that.$refs.payTestBarCode.processCatch()
          that.randomOrderNo() // 刷新订单号
        })
    },

    // 此处判断，微信，支付宝，聚合码，哪种支付方式一个都没配置，如果未配置，则不显示该板块，若等于-1 则表示不存在
    showTitle (parameterA) {
      if (this.appPaywayList.toString().indexOf(parameterA) === -1) {
        return false
      } else {
        return true
      }
    },
    // 聚合支付标题显示
    showJhTitle () {
      if (
        this.appPaywayList.toString().indexOf('WX') !== -1 ||
        this.appPaywayList.toString().indexOf('ALI') !== -1
      ) {
        return true
      } else {
        return false
      }
    },

    // 自定义金额输入框是否展示
    amountInputShow () {
      this.$nextTick(() => {
        // 输入框默认展示焦点
        this.$refs.amountInputFocus.focus()
      })
      this.amountInput = true
      this.paytestAmount = ''
    },

    // 条码弹窗点击x或者蒙版关闭
    testCodeChange () {
      this.randomOrderNo() // 刷新订单号
    }

    // handleCloseBarCode () {
    //   this.$refs.payTestBarCode.visible = false
    // }
  }
}
</script>

<style scoped lang="css">
@import "./payTest.css";
.gutter-box {
  background: #fff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.12),
    0 0 3px 0 rgba(0, 0, 0, 0.04);
  padding: 10px 5px;
  margin-bottom: 15px;
  height: 60px;
}
.gutter-box.active {
  border: 1px solid #1a53ff;
}
</style>
